<template>
  <q-card flat bordered v-bind="$attrs">
    <q-card-section>
      <div class="text-overline tw-text-secondary">{{ $attrs.label }}</div>
      <div class="h4 q-mb-xs">{{ $attrs.title }}</div>

      <div class="text-caption">
        <slot />
      </div>
    </q-card-section>

    <q-card-actions>
      <slot name="actions"></slot>
    </q-card-actions>
  </q-card>
</template>
