import { defineStore } from "pinia";
import { computed, ref } from "vue";
import paginationModel from "models/pagination.js";
import axios from "axios";

const DEFAULTNOTIFICATIONSPERPAGE = 10;

/**
 * Notification Store
 *
 * This store manages the state and actions related to notifications.
 */
export const useNotificationStore = defineStore("notifications", () => {
  // Refs
  const notifications = ref([]);
  const unreadNotificationsCount = ref(0);
  const isFetching = ref({
    notifications: false,
  });

  // Helpers
  /**
   * Gets the title of a notification based on its message.
   * @param {string} message - The notification message.
   * @returns {string} The title of the notification.
   */
  const getNotificationTitle = (message) => {
    const condition = (searchTerm) => {
      return message?.toLowerCase().includes(searchTerm) ?? false;
    };

    if (condition("actieplan")) return "Actieplan";
    if (condition("portfolio")) return "Portfolio";
    if (condition("feedback")) return "Feedback";
    if (condition("taak")) return "Taak";
    if (condition("leerling-stoplicht")) return "Leerling stoplicht";
    if (condition("activiteit")) return "Portfolio";
  };

  /**
   * Computes the user ID from local storage.
   * @returns {number} The user ID.
   */
  const userId = computed(() => {
    let id = JSON.parse(localStorage.getItem("user")).id;

    if (localStorage.getItem("employee_id")) {
      id = JSON.parse(localStorage.getItem("employee_id"));
    } else if (localStorage.getItem("student_user_id")) {
      id = JSON.parse(localStorage.getItem("student_user_id"));
    }

    return id;
  });

  /**
   * Sets the pagination metadata.
   * @param {Object} meta - The metadata from the backend.
   * @param {Object} pagination - The pagination model.
   * @returns {Object} The updated pagination model.
   */
  const setPaginationMeta = (meta, pagination) => {
    // Create a map to change the keys from the backend
    // We receive current_page but we have to send it as page
    const keyMap = {
      current_page: "page",
    };

    const updatedMeta = Object.fromEntries(
      Object.entries(meta)
        .map(([key, value]) => {
          // Map the keys to our set keys
          const newKey = keyMap[key] || key;

          return [newKey, value];
        })
        // Add only keys that are part of the default model
        .filter(([key]) => Object.keys(paginationModel).includes(key)),
    );

    return Object.assign(pagination, updatedMeta);
  };

  // Methods
  /**
   * Fetches notifications for a user.
   * @param {Object} options - The options for fetching notifications.
   * @param {number} [options.id=null] - The user ID.
   * @param {boolean} [options.specificUser=false] - Whether to fetch notifications for a specific user.
   * @param {Object} [options.pagination=null] - The pagination model.
   * @returns {Promise<Array<Object>>} The fetched notifications.
   */
  const fetchNotifications = async ({
    id = null,
    specificUser = false,
    pagination = null,
  } = {}) => {
    const { page, per_page } = pagination;

    isFetching.value["notifications"] = true;

    try {
      const {
        data: { data: fetchedData, meta },
      } = await axios.get(
        `/api/${specificUser ? "student" : "user"}/${
          id || userId.value
        }/notifications`,
        {
          params: {
            ...{ page, per_page },
          },
        },
      );

      setPaginationMeta(meta, pagination);
      notifications.value = fetchedData;

      return fetchedData;
    } catch (error) {
      console.log("🚀 ~ fetchNotifications ~ error", error);
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    } finally {
      isFetching.value["notifications"] = false;
    }
  };

  /**
   * Fetches the count of unread notifications.
   * @returns {Promise<number>} The count of unread notifications.
   */
  const fetchUnreadNotificationsCount = async () => {
    try {
      const { data } = await axios.get(
        `/api/user/${userId.value}/unseen-notification-count`,
      );

      unreadNotificationsCount.value = data.count;

      return unreadNotificationsCount.value;
    } catch (error) {
      console.log("🚀 ~ notificationStore.js ~ error:", error);
    }
  };

  /**
   * Sets a notification as read.
   * @param {Object} options - The options for setting a notification as read.
   * @param {number} options.notificationId - The ID of the notification to set as read.
   * @returns {Promise<Object>} The response data.
   */
  const setNotificationAsRead = async ({ notificationId } = {}) => {
    try {
      const { data } = await axios.post(
        `/api/user/${userId.value}/set-notification-read/${notificationId}`,
      );

      return data;
    } catch (error) {
      console.log("🚀 ~ notificationStore.js ~ error:", error);
    }
  };

  /**
   * Sets all notifications as read.
   * @returns {Promise<Object>} The response data.
   */
  const setAllNotificationsAsRead = async () => {
    try {
      const { data } = await axios.post(
        `/api/user/${userId.value}/set-all-notifications-read`,
      );

      return data;
    } catch (error) {
      console.log("🚀 ~ notificationStore.js ~ error:", error);
    }
  };

  return {
    notifications,
    isFetching,
    fetchNotifications,
    fetchUnreadNotificationsCount,
    getNotificationTitle,
    setNotificationAsRead,
    setAllNotificationsAsRead,
    unreadNotificationsCount,
    DEFAULTNOTIFICATIONSPERPAGE,
  };
});
