<template>
  <MeetingModule>
    <c-field
      v-model="model.rating_type_ids"
      :rules="[required, min(2), max(4)]"
      :lazy-rules="true"
      hide-bottom-space
    >
      <div class="tw-grid tw-grid-cols-12 tw-gap-2">
        <div
          class="tw-col-span-6 tw-flex md:tw-col-span-3 lg:tw-col-span-2"
          v-for="{ id, name, description } in ratingTypes"
          :key="id"
        >
          <c-checkbox
            class="tw-flex-1"
            :val="id"
            name="rating_type_ids"
            v-model="model.rating_type_ids"
            :label="name"
            :tooltip="description"
            :disable="
              !isEditable ||
              (model?.rating_type_ids &&
                model.rating_type_ids.length >= 4 &&
                !model.rating_type_ids.includes(id))
            "
            @update:model-value="modelUpdate"
          />
        </div>
      </div>
    </c-field>
  </MeetingModule>
</template>

<script setup>
import { computed, onMounted } from "vue";
import MeetingModule from "components/meeting/modules/MeetingModule.vue";
import { useSettingsStore } from "store/modules/settingStore";
import { storeToRefs } from "pinia";
import { required, min, max } from "common/validationRules.js";

// Define the model
const model = defineModel({
  required: true,
  type: Object,
});

const isStepCompleted = defineModel("isStepCompleted");

const settingsStore = useSettingsStore();
const { fetchRatingTypes } = settingsStore;
const { ratingTypes } = storeToRefs(settingsStore);

const isEditable = computed(() => {
  if (!model.value?.status || model.value?.status === "concept") {
    return true;
  }

  return false;
});

// Update model value
const modelUpdate = (value) => {
  isStepCompleted.value = value && value.length > 1 && value.length < 5;
};

onMounted(async () => {
  await fetchRatingTypes();

  // Set a default value for the model when it's empty
  if (model.value?.rating_type_ids == null) {
    model.value.rating_type_ids = [];
  }

  // Check the model onMounted
  modelUpdate(model.value.rating_type_ids);
});
</script>
