export const paginationModel = {
  page: 1,
  per_page: 10,
  last_page: 1,
  from: null,
  to: null,
  total: null,
};

export default paginationModel;
