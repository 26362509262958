<template>
  <MeetingModule>
    <TransitionMeetingPlanning v-model="model" />
  </MeetingModule>
</template>

<script setup>
import { onMounted, watchEffect } from "vue";
import MeetingModule from "components/meeting/modules/MeetingModule.vue";
import TransitionMeetingPlanning from "components/meeting/TransitionMeetingPlanning.vue";

// Define the model
const model = defineModel({
  required: true,
  type: Object,
});
const isStepCompleted = defineModel("isStepCompleted");

// On model update
const modelUpdate = (value) => {
  isStepCompleted.value = value != null;
};

onMounted(async () => {
  modelUpdate(model.value.timeframe_settings);
});

watchEffect(() => {
  modelUpdate(model.value.timeframe_settings);
});
</script>
