import { extractComponentName } from "composables/useHelpers";

export const components = Object.entries(
  import.meta.glob("./**/*.vue", {
    import: "default",
    eager: true,
  }),
).reduce((result, [path, component]) => {
  const componentName = extractComponentName(path);

  if (componentName === null) {
    return;
  }

  result.push({
    name: componentName,
    component,
  });

  return result;
}, []);

export default components;
