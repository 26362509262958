const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("pages/auth/Login.vue"),
    meta: { title: "Inloggen", layout: "AuthLayout" },
  },
  {
    path: "/login-by-email",
    name: "loginByEmail",
    component: () => import("pages/auth/LoginByEmail.vue"),
    meta: { title: "Inloggen", layout: "AuthLayout" },
  },
  {
    path: "/inloggen",
    name: "Inloggen",
    component: () => import("pages/auth/LoginSso.vue"),
    meta: { title: "Inloggen", layout: "AuthLayout" },
  },
  {
    path: "/2fa",
    name: "twoFactorAuthentication",
    component: () => import("pages/auth/TwoFactorAuthentication.vue"),
    meta: {
      title: "Tweestapsverificatie",
      layout: "AuthLayout",
    },
  },
  {
    path: "/school-login-choice",
    name: "schoolLoginChoice",
    component: () => import("pages/auth/SchoolLoginChoice.vue"),
    meta: { title: "School kiezen", layout: "AuthLayout" },
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: () => import("pages/auth/ForgotPassword.vue"),
    meta: {
      title: "Wachtwoord vergeten",
      layout: "AuthLayout",
    },
  },
  {
    path: "/reset-password",
    name: "resetPassword",
    component: () => import("pages/auth/ResetPassword.vue"),
    meta: {
      title: "Wachtwoord vergeten",
      layout: "AuthLayout",
    },
  },
];

export default routes;
