import useHelpers from "composables/useHelpers.js";

const { mappedTerms } = useHelpers();

const routes = () => [
  {
    name: "myStudents",
    path: "/my-students",
    redirect: {
      name: "myStudentsStudentMeetings",
    },
    meta: {
      title: `Mijn ${mappedTerms().students}`,
      role: "teacher",
      isMenuItem: true,
      settingRequired: ["studentMeeting"],
      menuOrder: 4,
      icon: "fa-graduation-cap",
      roleRequired: [
        "webmaster",
        "manager",
        "organizer",
        "teamleader",
        "decaan",
        "teacher",
      ],
    },
    children: [
      {
        name: "myStudentsStudentMeetings",
        path: "meetings",
        component: () => import("pages/my-students/StudentMeetings.vue"),
        meta: {
          title: `Leerlingbespreking`,
          isSubmenuItem: true,
          settingRequired: ["studentMeeting"],
          roleRequired: [
            "webmaster",
            "manager",
            "organizer",
            "teamleader",
            "decaan",
            "teacher",
          ],
        },
      },
      {
        name: "myStudentsTransitionMeetings",
        path: "transition-meetings",
        component: () => import("pages/my-students/TransitionMeetings.vue"),
        meta: {
          title: "Bevorderingsbespreking",
          isSubmenuItem: true,
          settingRequired: ["transitionMeeting", "studentMeeting"],
          roleRequired: [
            "webmaster",
            "manager",
            "organizer",
            "teamleader",
            "decaan",
            "teacher",
          ],
        },
      },
      {
        path: "group-meetings",
        name: "myStudentsGroupMeetings",
        component: () => import("pages/my-students/GroupMeetings.vue"),
        meta: {
          title: "Groepsbespreking",
          isSubmenuItem: true,
          settingRequired: ["groupMeeting", "studentMeeting"],
          roleRequired: [
            "webmaster",
            "manager",
            "organizer",
            "teamleader",
            "decaan",
            "teacher",
          ],
        },
      },
      {
        path: "portfolio",
        name: "myStudentsPortfolio",
        component: () => import("pages/my-students/MyStudentsPortfolio.vue"),
        meta: {
          title: "Portfolio",
          isSubmenuItem: true,
          settingRequired: ["portfolio"],
          roleRequired: [
            "webmaster",
            "manager",
            "organizer",
            "teamleader",
            "decaan",
            "teacher",
          ],
        },
      },
      {
        path: "learning-objectives",
        name: "myStudentsLearningObjectives",
        component: () => import("pages/my-students/LearningObjectives.vue"),
        meta: {
          title: "Leerdoelen",
          isSubmenuItem: true,
          settingRequired: ["learningObjectives"],
          roleRequired: [
            "webmaster",
            "manager",
            "organizer",
            "teamleader",
            "decaan",
            "teacher",
          ],
        },
      },
    ],
  },
];

export default routes;
