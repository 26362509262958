const MINIMALTIMEGAPINMINUTES = 30;
export const DEFAULTSTARTTIME = "06:00";
export const DEFAULTENDTIME = "23:00";
export const DEFAULTTIMEOPTIONSGAPINMINUTES = 30;

/**
 * Generates an array of time options in given interval between the specified start and end times.
 *
 * @param {string} startTime - The starting time in HH:mm format. Defaults to "06:00".
 * @param {string} endTime - The ending time in HH:mm format. Defaults to "23:00".
 * @param {string} timeGap - The interval between time options in minutes. Defaults to 30 minutes
 * @returns {string[]} An array of time options in 30-minute intervals.
 */
export const generateTimeOptions = (
  startTime = DEFAULTSTARTTIME,
  endTime = DEFAULTENDTIME,
  timeGap = DEFAULTTIMEOPTIONSGAPINMINUTES,
) => {
  startTime = startTime || DEFAULTSTARTTIME;
  endTime = endTime || DEFAULTENDTIME;

  // Extract hours and minutes from startTime and endTime
  const [startHour, startMinute] = startTime.split(":").map(Number);
  const [endHour, endMinute] = endTime.split(":").map(Number);

  // Initialize an array to store time options
  const timeOptions = [];

  // Convert startTime and endTime to minutes since midnight
  const startTotalMinutes = startHour * 60 + startMinute;
  let endTotalMinutes = endHour * 60 + endMinute;

  // If endTime is less than startTime, add 24 hours to endTotalMinutes to account for crossing midnight
  if (endTotalMinutes < startTotalMinutes) {
    endTotalMinutes += 24 * 60;
  }

  // Iterate from startTime to endTime in timeGap intervals
  for (
    let currentMinutes = startTotalMinutes;
    currentMinutes <= endTotalMinutes;
    currentMinutes += timeGap
  ) {
    const currentHour = Math.floor(currentMinutes / 60) % 24; // Use modulo 24 to wrap around midnight
    const currentMinute = currentMinutes % 60;
    const formattedHour = currentHour.toString().padStart(2, "0");
    const formattedMinute = currentMinute.toString().padStart(2, "0");
    timeOptions.push(`${formattedHour}:${formattedMinute}`);
  }

  // Return the array of time options
  return timeOptions;
};

export const addMinimalRequiredTimeGap = (time) => {
  time = time || DEFAULTSTARTTIME;

  const [startHour, startMinute] = time.split(":").map(Number);
  const dateTime = new Date();

  dateTime.setHours(startHour, startMinute, 0);
  dateTime.setMinutes(dateTime.getMinutes() + MINIMALTIMEGAPINMINUTES);

  return `${dateTime.getHours()}:${dateTime.getMinutes()}`;
};
