<template>
  <div class="tw-relative tw-z-50 tw-mt-2 tw-w-full" v-if="tabMenuItems.length">
    <div class="tw-container tw-mx-auto tw-px-4">
      <q-tabs
        outside-arrows
        dense
        no-caps
        indicator-color="secondary"
        align="left"
      >
        <template v-for="(subItem, key) in tabMenuItems">
          <q-route-tab
            :key="`subitem-${key}`"
            v-if="showSubItem(subItem)"
            clickable
            v-close-popup
            :to="{
              name: subItem.name,
            }"
          >
            <q-item-section>{{ generateMenuTitle(subItem) }}</q-item-section>
          </q-route-tab>
        </template>
      </q-tabs>
    </div>
  </div>
</template>

<script setup>
import { useMenuStore } from "store/modules/menuStore.js";
import { storeToRefs } from "pinia";
import useHelpers from "composables/useHelpers";

const { generateMenuTitle } = useHelpers();

const menuStore = useMenuStore();
const { showSubItem } = menuStore;
const { tabMenuItems } = storeToRefs(menuStore);
</script>
