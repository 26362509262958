<template>
  <div
    @click="scrollToTop"
    class="backtotop tw-group tw-fixed tw-bottom-[16px] tw-right-4 tw-z-[999] tw-flex tw-h-[48px] tw-w-[48px] tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-full tw-border tw-border-[#ccc] tw-bg-primary tw-text-white tw-transition-all hover:!tw-opacity-[90%] sm:tw-right-8 md:tw-p-4"
    :class="isVisible ? 'visible' : ''"
  >
    <q-icon name="fa-solid fa-chevron-up" />
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";

const isVisible = ref(false);

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

onMounted(() => {
  window.addEventListener("scroll", () => {
    const scrollHeight = window.scrollY;
    isVisible.value = scrollHeight > 1000;
  });
});
</script>

<style lang="scss">
.backtotop {
  @apply tw-pointer-events-none tw-translate-y-[10px] tw-opacity-0;
  transition: all 0.2s ease-in-out;

  &.visible {
    @apply tw-pointer-events-auto tw-translate-y-0 tw-opacity-100;
  }
}
</style>
