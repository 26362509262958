import { pathToComponentName } from "common/componentHelpers";

export default {
  install: (app) => {
    Object.entries(
      import.meta.glob("./*.vue", { eager: true, import: "default" }),
    ).forEach(([filePath, definition]) => {
      const componentName = pathToComponentName(filePath);

      app.component(componentName, definition);
    });
  },
};
