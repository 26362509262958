<template>
  <MeetingModule v-if="shouldShow">
    <c-radio-group
      class="tw-gap-2"
      :options="radioOptions"
      v-model="model.allow_grades"
    />
  </MeetingModule>
</template>

<script setup>
import { onMounted, ref, watchEffect, computed } from "vue";
import MeetingModule from "components/meeting/modules/MeetingModule.vue";

// Define the model
const model = defineModel({
  required: true,
  type: Object,
});
const isStepCompleted = defineModel("isStepCompleted");

// Refs
const radioOptions = ref([
  {
    label: "Inschakelen",
    value: 1,
  },
  {
    label: "Uitschakelen",
    value: 0,
  },
]);

// Computed
const shouldShow = computed(() => {
  return model.value.mail_student != null;
});

// On model update
const modelUpdate = (value) => {
  isStepCompleted.value = value != null;
};

onMounted(async () => {
  if (model.value?.allow_grades === undefined) {
    model.value.allow_grades = null;
  }

  modelUpdate(model.value.allow_grades);
});

watchEffect(() => {
  modelUpdate(model.value.allow_grades);
});
</script>
