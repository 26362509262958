<!-- WIP -->
<template>
  <div v-if="error.hasError" class="error-message">
    <span role="alert">{{ error.message }}</span>
  </div>
</template>

<script setup>
import { computed } from "vue";

const model = defineModel();

// Create a computed property that checks the model value and the rules, if they do not match then return the error message.
const error = computed(() => {
  const error = { hasError: false, message: "" };

  props.rules.forEach((rule) => {
    console.log("🚀 ~ props.rules.forEach ~ rule:", rule(model.value));
    const result = rule(model.value);
    console.log("🚀 ~ hasError ~ result:", result);

    if (result !== true) {
      error.hasError = true;
      error.message = result;

      return error;
    }
  });

  return error;
});

const props = defineProps({
  rules: {
    type: Array,
    default: () => [],
  },
});
</script>

<style lang="scss">
.error-message {
  @apply tw-flex tw-flex-wrap tw-items-start tw-px-[12px] tw-pt-[8px] tw-text-[12px];

  span[role="alert"] {
    @apply tw-flex-1 tw-break-words tw-leading-none tw-text-negative;
  }
}
</style>
