<template>
  <div class="tw-flex tw-items-center tw-justify-center tw-gap-2">
    <q-pagination
      flat
      gutter=".5em"
      active-design="unelevated"
      v-model="model.page"
      :max="model.last_page"
      boundary-numbers
      :max-pages="6"
      v-bind="$attrs"
      @update:model-value="modelUpdate"
    />
  </div>
</template>

<script setup>
const model = defineModel({
  type: Object,
  default: () => {},
  required: true,
});

const emit = defineEmits(["update:model-value"]);

const modelUpdate = () => {
  emit("update:model-value", model.value);
};
</script>
