<template>
  <div class="tw-relative tw-z-50 tw-w-full tw-bg-gray-100">
    <div class="tw-container tw-mx-auto tw-px-4">
      <q-tabs no-caps align="left">
        <q-route-tab
          v-for="(item, key) in items"
          :key="key"
          :to="{
            name: item.name,
          }"
        >
          <!-- This must be placed on QRouteTab to prevent redirect on click when children. -->
          <!-- @click="hasDropdownChild(item) && $event.preventDefault()"-->
          <div>
            {{ generateMenuTitle(item) }}
            <!--            <q-icon-->
            <!--              v-if="hasDropdownChild(item)"-->
            <!--              size="20px"-->
            <!--              name="arrow_drop_down"-->
            <!--            />-->
          </div>
          <!--          <c-menu v-if="hasDropdownChild(item)">-->
          <!--            <q-list dense>-->
          <!--              <template v-for="(subItem, key) in item.children">-->
          <!--                <q-item-->
          <!--                  :key="`subitem-${key}`"-->
          <!--                  v-if="showSubItem(subItem)"-->
          <!--                  clickable-->
          <!--                  v-close-popup-->
          <!--                  :to="{-->
          <!--                    name: subItem.name,-->
          <!--                  }"-->
          <!--                >-->
          <!--                  <q-item-section>{{ subItem.meta.title }}</q-item-section>-->
          <!--                </q-item>-->
          <!--              </template>-->
          <!--            </q-list>-->
          <!--          </c-menu>-->
        </q-route-tab>
      </q-tabs>
    </div>
  </div>
</template>

<script setup>
import { useMenuStore } from "store/modules/menuStore.js";
import useHelpers from "composables/useHelpers";

const { generateMenuTitle } = useHelpers();

const menuStore = useMenuStore();
const { hasDropdownChild, showSubItem } = menuStore;

defineProps({
  items: {
    type: Array,
    required: true,
  },
});
</script>

<style lang="scss">
.q-tabs--horizontal {
  .q-tabs__arrow {
    height: unset !important;

    &--left,
    &--right {
      top: 4px !important;
      bottom: 4px !important;
    }
  }
}
</style>
