import * as Sentry from "@sentry/vue";
import useHelpers from "composables/useHelpers.js";
import { ref } from "vue";

export default {
  install: async (app) => {
    if (import.meta.env.VITE_APP_ENV !== "development") {
      const { version } = await import("~/package.json");
      const {
        schoolId,
        employeeId,
        studentId,
        user,
        firstName,
        schoolName,
        currentShortage,
      } = useHelpers();

      Sentry.setUser({
        fullName: firstName ?? "",
        email: user?.email ?? "",
      });

      const tags = ref({
        "catwise-version": version,
        "catwise-school-name": schoolName,
        "catwise-school-id": schoolId,
        "catwise-employee-id": employeeId,
        "catwise-current-shortage": currentShortage,
        "catwise-student-id": studentId,
      });

      const translations = {
        triggerLabel: "Probleem melden",
        triggerAriaLabel: "Probleem melden",
        formTitle: "Probleem melden",
        submitButtonLabel: "Versturen",
        cancelButtonLabel: "Annuleren",
        confirmButtonLabel: "Bevestigen",
        addScreenshotButtonLabel: "Voeg een screenshot toe",
        removeScreenshotButtonLabel: "Verwijder screenshot",
        nameLabel: "Naam",
        namePlaceholder: "Vul je naam in",
        emailPlaceholder: "Vul je e-mailadres in",
        isRequiredLabel: "Verplicht",
        messageLabel: "Bericht",
        messagePlaceholder:
          "Wat wil je ons vertellen? Voeg een screenshot toe indien nodig!",
        successMessageText: "Bedankt voor je feedback!",
      };

      Sentry.init({
        app,
        dsn: "https://248bce06f2d99e24acf38a2ce1125cc9@o4506592533610496.ingest.sentry.io/4506672063905792",
        environment: import.meta.env.VITE_APP_ENV,
        trackComponents: true,
        integrations: [
          Sentry.browserTracingIntegration({
            instrumentNavigation: false,
            instrumentPageLoad: false,
          }),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
          Sentry.feedbackIntegration({
            ...translations,
            colorScheme: "light",
            enableScreenshot: true,
            showBranding: false,
            tags: tags.value,
            useSentryUser: {
              name: "fullName",
              email: "email",
            },
          }),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        beforeSend(event, hint) {
          const error = hint?.originalException;
          // Check if the error is an Axios 401 error
          if (error?.isAxiosError && error.response?.status === 401) {
            // Discard the error by returning null
            return null;
          }
          // Otherwise, send the event to Sentry
          return event;
        },
      });
    }
  },
};
