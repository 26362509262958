<template>
  <MeetingModule>
    <MeetingPlanning
      :mail-student="model.mail_student"
      @update-val="updateTimeframeValues"
    />
  </MeetingModule>
</template>

<script setup>
import { onMounted, watchEffect } from "vue";
import MeetingModule from "components/meeting/modules/MeetingModule.vue";
import MeetingPlanning from "components/meeting/MeetingPlanning.vue";

// Define the model
const model = defineModel({
  required: true,
  type: Object,
});
const isStepCompleted = defineModel("isStepCompleted");

const updateTimeframeValues = (timeSettings) => {
  model.value.timeframe_settings = timeSettings.data;
};

// On model update
const modelUpdate = (value) => {
  isStepCompleted.value = value != null;
};

onMounted(async () => {
  modelUpdate(model.value.timeframe_settings);
});

watchEffect(() => {
  modelUpdate(model.value.timeframe_settings);
});
</script>
