<template>
  <c-dialog ref="dialogRef" class="event-dialog" :return-data="_model">
    <div v-if="isReady" class="tw-flex tw-flex-col tw-gap-4">
      <c-input label="Naam van de periode" v-model="_model.name" autofocus />

      <c-button
        :loading="deletingPeriod"
        class="tw-self-start"
        label="Periode verwijderen"
        icon="fas fa-trash-alt"
        color="negative"
        @click="removePeriod()"
      />
    </div>
  </c-dialog>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { cloneDeep } from "lodash";
import { useNotifyStore } from "store/modules/notifyStore";

// Notify store
const notifyStore = useNotifyStore();
const { setNotification } = notifyStore;

const props = defineProps({
  type: {
    type: [String, null],
    default: () => null,
  },
  model: {
    type: [Object, null],
    default: () => null,
  },
  manager: {
    type: Object,
    default: () => ({}),
  },
});

const isReady = ref(false);
const _model = ref(null);
const dialogRef = ref(null);
const deletingPeriod = ref(false);

const removePeriod = async () => {
  try {
    // Rerwite the remove period so it uses the meta.manager in the period class to remove it from the periods list
    await _model.value.planningMeta.manager.removePeriod(props.model.id);

    dialogRef.value.dialogRef.hide();

    setNotification({
      key: "period-planning",
      message: "Planning is succesvol verwijderd",
      type: "success",
    });
  } catch (error) {
    console.log("🚀 ~ removePeriod ~ error:", error);

    setNotification({
      key: "period-planning",
      message:
        error?.response?.data?.message ||
        error?.message ||
        "Er is iets misgegaan bij het verwijderen van de periode. Probeer het opnieuw.",
      type: "error",
    });
  } finally {
    deletingPeriod.value = false;
  }
};

onMounted(() => {
  _model.value = cloneDeep(props.model);

  isReady.value = true;
});
</script>

<style lang="scss">
.q-dialog.event-dialog {
  .q-dialog__inner > div {
    @apply tw-max-h-[60vh] tw-w-full sm:tw-w-[75vw];
  }
}
</style>
