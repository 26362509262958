import { Quasar, Notify, Dialog } from "quasar";

// Icons
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";

// Styles
import "quasar/src/css/index.sass";

// Language
import nl from "quasar/lang/nl";

export default {
  install: (app) => {
    app.use(Quasar, {
      lang: nl,
      plugins: {
        Notify,
        Dialog,
      },
      config: {
        notify: {
          position: "bottom",
          timeout: 3000,
          textColor: "white",
          actions: [{ icon: "close", color: "white" }],
        },
      },
    });
  },
};
