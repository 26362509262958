<template>
  <c-dialog type="" :return-data="model">
    <c-input-select v-model="model" autogrow />
    <pre>startWeek: {{ startWeek }}, weeks: {{ weeks }}</pre>
  </c-dialog>
</template>

<script setup>
const model = defineModel({
  type: Object,
  required: true,
  default: () => {},
});

const props = defineProps({
  weeks: {
    type: Array,
    required: true,
    default: () => [],
  },
  startWeek: {
    type: [Number | null],
    default: () => null,
  },
});
</script>
