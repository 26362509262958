<template>
  <section class="form-group">
    <h2 v-if="title">{{ title }}</h2>
    <h3 v-if="subTitle">{{ subTitle }}</h3>
    <p>{{ description }}</p>

    <slot />
  </section>
</template>

<script setup>
import { toRefs } from "vue";

const props = defineProps({
  title: String,
  subTitle: String,
  description: String,
});

const { title, description } = toRefs(props);
</script>

<style lang="scss">
.form-group {
  @apply tw-mb-4;
}

.meeting-wrapper {
  .form-group {
    @apply tw-mb-12;
  }
}
</style>
