import { acceptHMRUpdate, defineStore, storeToRefs } from "pinia";
import { useSettingsStore } from "store/modules/settingStore.js";
import { computed, ref } from "vue";

/**
 * Module Store
 *
 * This store manages the state and actions related to modules.
 */
export const useModuleStore = defineStore("module", () => {
  const settingStore = useSettingsStore();
  const { getSchoolSettings } = settingStore;
  const { schoolSettings, gradesEnabled } = storeToRefs(settingStore);

  /**
   * List of all available modules.
   * @type {import('vue').Ref<Array<string>>}
   */
  const allModules = ref([
    "studentMeeting",
    "portfolio",
    "transitionMeeting",
    "learningObjectives",
  ]);

  /**
   * Checks if a given module has its setting enabled.
   * @param {string} module - The name of the module.
   * @returns {boolean} True if the module setting is enabled, false otherwise.
   */
  const hasSettingEnabled = (module) => {
    switch (module) {
      case "transitionMeeting":
        // Always available for all schools
        return true;
      case "groupMeeting":
        return schoolSettings.value.root_settings.group_meeting;
      case "studentMeeting":
        return schoolSettings.value.student_meeting_settings.enabled;
      case "portfolio":
        return schoolSettings.value.portfolio_settings.enabled;
      case "learningObjectives":
        return schoolSettings.value.learning_objective_settings.enabled;
      case "manageData":
        return !schoolSettings.value.school_settings.manage_data;
      case "gradesEnabled":
        return gradesEnabled.value;
    }
  };

  /**
   * Maps a module name to its display name.
   * @param {string} module - The name of the module.
   * @returns {string} The display name of the module.
   */
  const mapModuleName = (module) => {
    switch (module) {
      case "transitionMeeting":
        return "overgangsbespreking";
      case "studentMeeting":
        return "leerlingbespreking";
      case "portfolio":
        return "portfolio";
      case "learningObjectives":
        return "leerdoelen";
    }
  };

  /**
   * Computes the list of active modules based on their settings.
   * @returns {Array<string>} The list of active modules.
   */
  const activeModules = computed(() => {
    // Use the Array.filter() method to filter out the modules that are not enabled
    const enabledModules = allModules.value.filter((module) =>
      hasSettingEnabled(module),
    );

    return enabledModules;
  });

  return { hasSettingEnabled, activeModules, mapModuleName };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useModuleStore, import.meta.hot));
}
