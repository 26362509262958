export const iconByFileExtension = (ext) => {
  switch (ext) {
    case "xls":
    case "xlsx":
    case "numbers":
      return "fa-file-excel";
    case "ppt":
    case "pptx":
    case "key":
      return "fa-file-powerpoint";
    case "doc":
    case "docx":
    case "pages":
      return "fa-file-word";
    case "pdf":
      return "fa-file-pdf";
    case "jpg":
    case "jpeg":
    case "png":
      return "fa-file-image";
  }
};
