<template>
  <div
    class="tw-relative -tw-mb-2 tw-bg-secondary tw-py-2 tw-pr-12 tw-text-center tw-font-heading tw-text-white"
    v-if="globals.statusMessage && globals.statusMessage.active"
  >
    {{ globals.statusMessage.message }}
    <div
      @click="globals.statusMessage.active = false"
      class="tw-group tw-absolute tw-right-2 tw-top-1/2 tw-aspect-square tw-h-5 tw--translate-y-1/2 tw-cursor-pointer tw-rounded-full tw-bg-secondary-800 tw-transition-all hover:tw-bg-secondary-900"
    >
      <div
        class="tw-absolute tw-left-1/2 tw-top-1/2 tw-h-3 tw-w-[.15rem] tw-origin-center tw--translate-x-1/2 tw--translate-y-1/2 tw-rotate-45 tw-transform tw-rounded-full tw-bg-white tw-transition-all group-hover:tw-rotate-[60deg]"
      ></div>
      <div
        class="tw-absolute tw-left-1/2 tw-top-1/2 tw-h-3 tw-w-[.15rem] tw-origin-center tw--translate-x-1/2 tw--translate-y-1/2 tw--rotate-45 tw-transform tw-rounded-full tw-bg-white tw-transition-all group-hover:tw-rotate-[-60deg]"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { useGlobalStore } from "@/stores/global/global";
import { onMounted } from "vue";
import axios from "axios";
import { storeToRefs } from "pinia";

// Store
const globalStore = useGlobalStore();
const { globals } = storeToRefs(globalStore);

onMounted(async () => {
  if (
    globals.value.statusMessage &&
    globals.value.statusMessage.message === null
  ) {
    await getStatusMessage();
  }
});

const getStatusMessage = async () => {
  try {
    const { version } = await import("~/package.json");

    const { data } = await axios.get("/api/status-message", {
      headers: { "version-number": version },
    });

    globals.value.statusMessage = {
      active: !!data.messages,
      message: data.messages,
    };
  } catch (error) {
    console.log("🚀 ~ getStatusMessage ~ error:", error);

    globals.value.statusMessage = {
      active: false,
      message: null,
    };
  }
};
</script>
