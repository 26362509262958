<template>
  <div
    class="primary-button tw-inline-flex tw-h-8 tw-cursor-pointer tw-select-none tw-items-center tw-justify-center tw-rounded tw-px-4 tw-text-white tw-transition-colors"
    :class="{
      'cursor-disabled tw-pointer-events-none tw-opacity-50':
        disabled || loading,
    }"
    v-bind="$attrs"
  >
    <slot v-if="!loading" />
    <span v-else>
      <q-icon name="fa-duotone fa-spinner fa-spin-pulse"></q-icon>
    </span>
  </div>
</template>

<script setup>
import { toRefs } from "vue";

const props = defineProps({
  disabled: {
    type: Boolean,
    required: false,
  },
  loading: {
    type: Boolean,
    required: false,
  },
});

const { disabled, loading } = toRefs(props);
</script>

<style lang="scss" scoped>
.primary-button {
  @apply tw-bg-primary hover:tw-bg-primary-900;

  &.danger {
    @apply tw-bg-red-600;

    &:hover {
      @apply tw-bg-red-700;
    }
  }

  &.success {
    @apply tw-bg-green-600;

    &:hover {
      @apply tw-bg-green-700;
    }
  }

  &.gray {
    @apply tw-border tw-border-gray-200 tw-bg-white tw-text-primary;

    &:hover {
      @apply tw-bg-gray-50;
    }
  }

  &.darkgray {
    @apply tw-bg-gray-400 tw-text-white;

    &:hover {
      @apply tw-bg-gray-700;
    }
  }

  &.small {
    @apply tw-px-2;
  }
}
</style>
