<template>
  <q-dialog ref="dialogRef" :persistent @hide="onDialogHide">
    <q-card class="q-dialog-plugin tw-flex tw-flex-col !tw-shadow-xl">
      <q-card-section
        class="tw-flex tw-items-center tw-gap-4 tw-bg-primary !tw-px-4 tw-text-white"
      >
        <q-icon
          v-if="dialogType.icon"
          :class="dialogType.class"
          :name="dialogType.icon"
        />

        <h3
          class="tw-flex-1 tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-text-xl tw-font-semibold"
        >
          {{ title }}
        </h3>

        <c-button-icon icon="fas fa-times" @click="onDialogCancel" />
      </q-card-section>

      <q-card-section class="!tw-px-4" v-if="message">
        <p class="tw-m-0 tw-text-base">{{ message }}</p>
      </q-card-section>

      <q-card-section class="tw-overflow-auto !tw-p-4 tw-py-0">
        <slot />
      </q-card-section>

      <q-card-actions class="!tw-px-4" align="right">
        <c-button
          v-if="cancel"
          color="transparent"
          text-color="gray"
          label="Annuleren"
          @click="onDialogCancel"
        />

        <c-button
          :color="dialogType.color"
          :label="primaryLabel"
          @click="onOKClick"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { useDialogPluginComponent } from "quasar";
import { computed } from "vue";

const props = defineProps({
  title: {
    type: String,
    required: true,
    default: () => "",
  },
  message: {
    type: [String, null],
    default: () => null,
  },
  cancel: {
    type: Boolean,
    default: () => true,
  },
  persistent: {
    type: Boolean,
    default: () => false,
  },
  primaryLabel: {
    type: String,
    default: () => "",
  },
  type: {
    type: String,
  },
  returnData: {
    type: [String, Object, Array],
    default: "",
  },
});

defineEmits([...useDialogPluginComponent.emits]);

const dialogType = computed(() => {
  switch (props.type) {
    case "warning":
      return {
        class: "tw-text-warning",
        icon: "fas fa-exclamation-triangle",
        color: "warning",
      };
    case "negative":
      return {
        class: "tw-text-negative",
        icon: "fas fa-exclamation-circle",
        color: "negative",
      };
    case "success":
      return {
        class: "tw-text-success",
        icon: "fas fa-check-circle",
        color: "positive",
      };
    case "info":
      return {
        class: "tw-text-info",
        icon: "fas fa-info-circle",
        color: "primary",
      };
    default:
      return {
        color: "primary",
      };
  }
});

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

defineExpose({ dialogRef });

function onOKClick() {
  onDialogOK(props.returnData);
}
</script>
