<template>
  <div class="mobile-menu">
    <div
      class="tw-fixed tw-left-0 tw-right-0 tw-top-0 tw-z-[99] tw-flex tw-h-1/4 tw-items-end tw-overflow-hidden tw-bg-primary tw-px-8 tw-pb-8 tw-text-white tw-transition-all tw-delay-[50ms] tw-duration-300"
      :class="{
        'tw-translate-x-0': menuIsOpen,
        'tw-translate-x-full': !menuIsOpen,
      }"
    >
      <div class="tw-flex tw-items-center tw-justify-start tw-gap-x-4">
        <div
          class="tw-relative tw-aspect-square tw-w-20 tw-overflow-hidden tw-rounded-full tw-bg-white"
        >
          <img
            class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-object-cover"
            :src="
              user.profile_image
                ? user.profile_image
                : '/images/empty-profile-pic.webp'
            "
          />
        </div>
        <div>
          <div class="tw-font-bold">
            {{ user.first_name }} {{ user.middle_name }} {{ user.last_name }}
          </div>
          <div class="tw-text-xs tw-font-light">{{ schoolName }}</div>
        </div>
      </div>
      <div
        @click="closeMenu"
        class="tw-absolute tw-right-6 tw-top-6 tw-flex tw-aspect-square tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-50 tw-text-gray-500"
      >
        <q-icon name="fa-solid fa-times" size="16px" />
      </div>
      <div
        class="tw-pointer-events-none tw-absolute tw-left-0 tw-top-0 -tw-z-10 tw-h-full tw-w-full tw-opacity-30"
      >
        <img
          class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-scale-150 tw-object-cover"
          src="/images/school_items_svg.svg"
        />
      </div>
    </div>
    <div
      class="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-z-[99] tw-h-3/4 tw-bg-white tw-px-8 tw-transition-all tw-delay-[50ms] tw-duration-300"
      :class="{
        'tw-translate-x-0': menuIsOpen,
        'tw-translate-x-full': !menuIsOpen,
      }"
    >
      <q-scroll-area :visible="false" class="fit">
        <div class="tw-mt-8 tw-pb-32">
          <div
            v-for="(item, key) in menuItems"
            :key="`item-${key}`"
            class="mobile-menu__menu-animation"
            :style="{
              transitionDelay: `${key * 65 + 150}ms`,
            }"
            :class="{
              'mobile-menu__menu-animation--open': menuIsOpen,
              'mobile-menu__menu-animation--close': !menuIsOpen,
            }"
          >
            <component
              :is="item.children?.length ? 'div' : 'router-link'"
              class="mobile-menu__menu-item"
              :class="{
                'has-children': item.children && item.children.length,
                'has-active-child': isChildRouteActive(item),
              }"
              :to="{
                name: item.name,
              }"
              @click="item.children?.length ? toggleSubmenu($event, key) : null"
            >
              <div class="mobile-menu__menu-item--inner">
                <q-icon :name="`fa-solid ${item.meta.icon}`" :size="iconSize" />
                {{ generateMenuTitle(item) }}
              </div>
              <q-icon
                class="tw-transform tw-transition-transform"
                :class="{
                  '-tw-rotate-180': submenuIsOpen[key],
                }"
                v-if="item.children && item.children.length"
                size="12px"
                name="fa-solid fa-chevron-down"
              />
            </component>

            <div
              class="submenu tw-ml-12"
              v-if="item.children && item.children.length && submenuIsOpen[key]"
            >
              <router-link
                v-for="(subItem, key) in item.children"
                :key="`subitem-${key}`"
                :to="{
                  name: subItem.name,
                }"
                class="mobile-menu__menu-item"
              >
                <q-item-section>
                  <div class="mobile-menu__menu-item--inner">
                    <q-icon
                      :name="`fa-solid ${subItem.meta.icon}`"
                      :size="iconSize"
                    />
                    {{ generateMenuTitle(subItem) }}
                  </div>
                </q-item-section>
              </router-link>
            </div>
          </div>

          <router-link
            class="mobile-menu__menu-item mobile-menu__menu-animation tw-delay-[650ms]"
            :class="{
              'mobile-menu__menu-animation--open': menuIsOpen,
              'mobile-menu__menu-animation--close': !menuIsOpen,
            }"
            :style="{
              transitionDelay: `${menuItems.length * 100}ms`,
            }"
            :to="{
              name: 'notifications',
            }"
          >
            <div class="mobile-menu__menu-item--inner">
              <q-icon name="fa-solid fa-bell" :size="iconSize" />
              Notificaties
            </div>
            <q-badge
              color="red"
              class="tw-ml-2"
              v-if="unreadNotificationsCount"
            >
              {{ unreadNotificationsCount }}
            </q-badge>
          </router-link>

          <router-link
            class="mobile-menu__menu-item mobile-menu__menu-animation"
            :style="{
              transitionDelay: `${menuItems.length * 100 + 50}ms`,
            }"
            :class="{
              'mobile-menu__menu-animation--open': menuIsOpen,
              'mobile-menu__menu-animation--close': !menuIsOpen,
            }"
            :to="{
              name: 'settings',
            }"
            v-if="globals.showSettingsPage"
          >
            <div class="mobile-menu__menu-item--inner">
              <q-icon name="fa-solid fa-gear" :size="iconSize" />
              Instellingen
            </div>
          </router-link>

          <div class="mobile-menu__menu-item" v-if="locations?.length">
            Locatie veranderen
            <c-menu>
              <q-list dense>
                <q-item
                  v-for="(location, key) in locations"
                  :key="key"
                  clickable
                  v-close-popup
                  @click="loginAsSchool(location)"
                >
                  <q-item-section>{{ location.name }}</q-item-section>
                </q-item>
              </q-list>
            </c-menu>
          </div>
        </div>
      </q-scroll-area>

      <div
        class="tw-sticky tw-bottom-0 tw-flex tw-w-full tw-items-center tw-gap-x-4 tw-border-t tw-bg-white tw-px-8 tw-py-6 hover:tw-cursor-pointer"
        @click="logout"
      >
        <q-icon name="fa-solid fa-sign-out" :size="iconSize" />
        Uitloggen
      </div>
    </div>
  </div>
</template>

<script setup>
import { useMenuStore } from "store/modules/menuStore.js";
import { useUserStore } from "store/modules/userStore.js";
import { storeToRefs } from "pinia";
import useHelpers from "composables/useHelpers.js";
import { onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useGlobalStore } from "@/stores/global/global";

// menuStore
const menuStore = useMenuStore();
const { logout, closeMenu, loginAsSchool } = menuStore;
const { menuItems, menuIsOpen, unreadNotificationsCount } =
  storeToRefs(menuStore);

// User store
const userStore = useUserStore();
const { fetchCurrentUser } = userStore;
const { user } = storeToRefs(userStore);

// Router
const route = useRoute();

// Globals
const globalStore = useGlobalStore();
const { globals } = storeToRefs(globalStore);

// Helpers
const { schoolName, generateMenuTitle } = useHelpers();

const submenuIsOpen = ref([]);
const locations = ref([]);
const iconSize = ref("14px");

const toggleSubmenu = (event, index) => {
  submenuIsOpen.value[index] = !submenuIsOpen.value[index];
};

const isChildRouteActive = (item) => {
  return item.children?.some((child) => {
    return route.name === child.name;
  });
};

onMounted(async () => {
  await fetchCurrentUser();
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", null);
});

watch(menuIsOpen, () => {
  if (menuIsOpen.value) {
    document.body.classList.add("tw-overflow-hidden");
  } else {
    document.body.classList.remove("tw-overflow-hidden");
  }
});

watch(route, () => {
  submenuIsOpen.value = [];
  closeMenu();
});
</script>

<style lang="scss" scoped>
.mobile-menu {
  &__menu-item {
    @apply tw-mb-2 tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-x-4 tw-rounded-md  tw-px-8 tw-py-3 tw-text-[#525252] tw-transition-colors hover:tw-cursor-pointer;

    &--inner {
      @apply tw-flex tw-items-center tw-gap-x-4;
    }

    &.router-link-active {
      @apply tw-bg-primary tw-text-white tw-no-underline hover:tw-opacity-90;
    }

    &.has-children.router-link-active {
      @apply tw-bg-white  tw-text-[#525252];
    }

    &.has-active-child {
      @apply tw-bg-gray-100;
    }
  }

  &__menu-animation {
    @apply tw-transform tw-transition-all tw-duration-500;

    &--open {
      @apply tw-translate-x-0 tw-opacity-100;
    }
    &--close {
      @apply tw-translate-x-16 tw-opacity-0  tw-duration-300;

      transition-delay: 0ms !important;
    }
  }
}
</style>
