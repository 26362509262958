<template>
  <div
    class="tw-text-4xs tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-overflow-hidden tw-text-center"
  >
    <q-icon :name="`fa-duotone ${attachmentComponent}`" size="22px" />

    <div
      class="tw-line-clamp-2 tw-overflow-ellipsis tw-text-balance tw-break-all tw-text-2xs"
    >
      {{ attachment.name }}
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { iconByFileExtension } from "common/fileHelpers";

const props = defineProps({
  attachment: {
    type: Object,
    required: true,
  },
});

const attachmentComponent = computed(() => {
  const ext = props.attachment.name.split(".").pop().toLowerCase();

  return iconByFileExtension(ext);
});
</script>
