<template>
  <c-input-select
    v-bind="$attrs"
    v-model="model"
    :options="timeOptions"
    options-dense
    hide-bottom-space
    :error="errorMessage !== undefined"
    :error-message="errorMessage"
    :rules="rules"
  />
</template>

<script setup>
import { generateTimeOptions } from "common/timeOptions";
import { ref, computed, watch } from "vue";
import { addMinimalRequiredTimeGap } from "common/timeOptions";
import { DEFAULTSTARTTIME, DEFAULTENDTIME } from "common/timeOptions";

// Define the model
const model = defineModel({
  required: true,
  type: [String, null],
  default: () => "",
});

// Define the props
const props = defineProps({
  minTime: {
    type: String,
    required: false,
    default: ({ minTime }) => {
      return minTime === "" || minTime == null ? DEFAULTSTARTTIME : minTime;
    },
  },
  maxTime: {
    type: String,
    required: false,
    default: ({ maxTime }) => {
      return maxTime === "" || maxTime == null ? DEFAULTENDTIME : maxTime;
    },
  },
  timeOptionsGapInMinutes: {
    type: Number,
    required: false,
    default: () => 30,
  },
  compareTime: {
    type: Boolean,
    default: false,
  },
  addTimeGap: {
    type: Boolean,
    default: ({ addTimeGap, compareTime }) => {
      if (addTimeGap != null) {
        return addTimeGap;
      }

      return compareTime;
    },
  },
  required: {
    type: Boolean,
    default: false,
  },
});

// Set refs
const errorMessage = ref(undefined);

const timeOptions = computed(() => {
  // Return a list based on the min and max times
  if (props.compareTime) {
    // Check if there needs to be a gap between the times
    return generateTimeOptions(
      props.addTimeGap
        ? addMinimalRequiredTimeGap(props.minTime)
        : props.minTime,
      props.maxTime,
      props.timeOptionsGapInMinutes,
    );
  }

  // Return a default list with the set start and end time
  return generateTimeOptions(
    DEFAULTSTARTTIME,
    DEFAULTENDTIME,
    props.timeOptionsGapInMinutes,
  );
});

const isValidTime = (time) => {
  if (props.compareTime && time && !timeOptions.value.includes(time)) {
    errorMessage.value = "Deze tijd is niet geldig";

    return false;
  } else {
    errorMessage.value = undefined;

    return true;
  }
};

const rules = computed(() => {
  return [
    ...(props.required ? [(val) => !!val || "Dit veld is verplicht"] : []),
    (val) => isValidTime(val) || "Deze tijd is niet geldig",
  ];
});

// Compare the min date and the current date
watch(
  [() => timeOptions.value, () => model.value, () => props.compareTime],
  ([_, model]) => {
    isValidTime(model);
  },
  { immediate: true },
);
</script>
