<template>
  <q-slider
    v-model="model"
    :min="$attrs.min || 0"
    :max="$attrs.max || 100"
    label
  />
</template>

<script setup>
const model = defineModel({
  type: Number,
  default: () => 0,
  required: true,
});
</script>

<style lang="scss">
.q-slider {
  .q-slider__marker-labels-container {
    .q-slider__marker-labels:first-child {
      @apply tw-translate-x-0;
    }

    .q-slider__marker-labels:last-child {
      @apply -tw-translate-x-full;
    }
  }
}
</style>
