import { cloneDeep } from "lodash";

/**
 * This will generate a date string in the format of YYYY/MM/DD
 * @param {any} date - The date to be formatted. Defaults to Date.now()
 * @returns {any} A date string in the format of YYYY/MM/DD
 */
export const getDateString = (date = Date.now()) => {
  const selectedDate = new Date(date);
  const [day, month, year] = [
    selectedDate.getDate().toString().padStart(2, "0"),
    (selectedDate.getMonth() + 1).toString().padStart(2, "0"),
    selectedDate.getFullYear(),
  ];

  return `${year}/${month}/${day}`;
};

/**
 * This will generate a date string from the format of YYYY-MM-DD
 * @param {any} date - The date to be formatted
 * @returns {any} A date string in the format of YYYY/MM/DD
 */
export const getDateStringFromQuasar = (date) => {
  if (!date || typeof date !== "string") {
    return;
  }

  const [day, month, year] = date.split("-");

  return getDateString(`${year}/${month}/${day}`);
};

/**
 * This will generate a date string in the format of DD-MM-YYYY
 * @param {any} date - The date to be formatted. Defaults to Date.now()
 * @returns {any} A date string in the format of DD-MM-YYYY
 */
export const toDutchDateString = (date = Date.now()) => {
  const selectedDate = new Date(date);
  const [day, month, year] = [
    selectedDate.getDate().toString().padStart(2, "0"),
    (selectedDate.getMonth() + 1).toString().padStart(2, "0"),
    selectedDate.getFullYear(),
  ];

  return `${day}-${month}-${year}`;
};

/**
 * This will return a date object from a dutch date and time string
 * @param {any} dateString - In the format: DD-MM-YYYY
 * @param {any} timeString = "00:00"
 * @returns {any}
 */
export const fromDutchToIntlDate = (dateString, timeString = "00:00") => {
  if (!dateString || typeof dateString !== "string") {
    return;
  }

  timeString = timeString || "00:00";

  // Date parts
  const dateParts = dateString.split("-");
  const day = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1;
  const year = parseInt(dateParts[2], 10);

  // Time parts
  const timeParts = timeString.split(":");
  const hours = parseInt(timeParts[0], 10);
  const minutes = parseInt(timeParts[1], 10);

  return new Date(year, month, day, hours, minutes);
};

/**
 * This function takes a dateTime string and outputs a Dutch date (DD-MM-YYYY)
 * and time (HH:MM) as a separate object.
 * @param {string} dateTime - The date and time string in ISO format.
 * @returns {object} An object containing the formatted date and time.
 */
export const fromIntlToDutchDateTime = (dateTime) => {
  if (!dateTime || typeof dateTime !== "string") {
    return;
  }

  const dateObj = new Date(dateTime);

  if (isNaN(dateObj)) {
    return;
  }

  const dateOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  const date = new Intl.DateTimeFormat("nl-NL", dateOptions).format(dateObj);
  const time = new Intl.DateTimeFormat("nl-NL", timeOptions).format(dateObj);

  return { date, time };
};

/**
 * Check if two dates are on the same day
 * @param {any} a - The first date to compare, b - The second date to compare
 * @returns {any}
 */
export const isSameDay = (a = Date.now(), b = Date.now()) => {
  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  );
};

/**
 * Creates a Date object from separate date and time strings.
 *
 * @param {string} dateStr - The date string in 'DD-MM-YYYY' format.
 * @param {string} timeStr - The time string in 'HH:mm' 24-hour format.
 * @returns {Date} A JavaScript Date object representing the combined date and time.
 */
export const createDateTimeFromDutchString = (dateStr, timeStr) => {
  // Split the date string into day, month, and year components
  const [day, month, year] = dateStr.split("-").map(Number);

  // Split the time string into hour and minute components
  const [hours, minutes] = timeStr.split(":").map(Number);

  // Create a Date object with the extracted components
  return new Date(year, month - 1, day, hours, minutes);
};

/**
 * Returns a date in the ISO format without taking the timezone into account
 * Om te janken dit, maar goed. Het is niet anders
 * @param {Date} date
 * @returns {String} a date string in the ISO format
 */
export const createISOFormatWithoutTimezone = (date = new Date()) => {
  if (!(date instanceof Date)) {
    throw Error("passed date is not a valid date", date);
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

  // Construct the ISO string in local time (ignoring timezone)
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
};

/**
 * Adds a specified number of days to the current date and returns the new date.
 *
 * @param {number} days - The number of days to add to the current date.
 * @returns {Date} The new date after adding the specified number of days.
 */
export const addDaysToDate = (days = 0) => {
  const currentDate = new Date();

  currentDate.setDate(currentDate.getDate() + days);

  return currentDate;
};

/**
 * Checks if the second time is later than the first time.
 *
 * @param {string} time1 - The first time string in 'HH:mm' format.
 * @param {string} time2 - The second time string in 'HH:mm' format.
 * @returns {boolean} True if the second time is later than the first time, false otherwise.
 */
export const isSecondTimeLater = (time1, time2) => {
  // Regex pattern to match 'HH:mm' format where HH is 00-23 and mm is 00-59
  const timePattern = /^(?:[01]\d|2[0-3]):[0-5]\d$/;

  // Check if both time strings match the pattern
  if (![time1, time2].every((time) => timePattern.test(time))) {
    return false;
  }

  // Parse the first time string
  const [hours1, minutes1] = time1.split(":").map(Number);

  // Parse the second time string
  const [hours2, minutes2] = time2.split(":").map(Number);

  // Convert both times to total minutes since midnight
  const totalMinutes1 = hours1 * 60 + minutes1;
  const totalMinutes2 = hours2 * 60 + minutes2;

  // Compare the total minutes
  return totalMinutes2 > totalMinutes1;
};

/**
 * This will return a nice month and year string
 * @param {number} month
 * @param {number} year
 * @returns {any}
 */
export const getDateFromMonthYear = (month, year) => {
  const date = new Date(year, month - 1);

  // Use Intl.DateTimeFormat to format the majority month and year
  return new Intl.DateTimeFormat("nl-NL", {
    month: "long",
    year: "numeric",
  }).format(date);
};

export const getWeekNumber = (date = new Date()) => {
  const _date = cloneDeep(date);

  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  _date.setUTCDate(_date.getUTCDate() + 4 - (_date.getUTCDay() || 7));

  // Get first day of year
  var yearStart = new Date(Date.UTC(_date.getUTCFullYear(), 0, 1));

  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(((_date - yearStart) / 86400000 + 1) / 7);

  // Return array of year and week number
  return [weekNo, _date.getUTCFullYear()];
};
