import axios from "axios";
import { acceptHMRUpdate, defineStore } from "pinia";
import { ref } from "vue";

/**
 * Store for managing user data.
 */
export const useUserStore = defineStore("user", () => {
  // Refs
  const user = ref({});

  // Actions
  /**
   * Fetches the current user data.
   * @param {Object} [options={}] - The options for fetching the current user.
   * @param {boolean} [options.forceFetch=false] - Whether to force fetch the data.
   * @returns {Promise<Object>} The fetched user data.
   */
  const fetchCurrentUser = async ({ forceFetch = false } = {}) => {
    if (Object.keys(user.value).length !== 0 && !forceFetch) {
      return;
    }

    try {
      const { data: fetchedUser } = await axios.get(`/api/user/current`);

      user.value = fetchedUser.data;

      return user.value;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  return {
    user,
    fetchCurrentUser,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
