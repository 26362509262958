<template>
  <q-form ref="formRef" v-bind="$attrs">
    <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </q-form>
</template>

<script setup>
import { ref } from "vue";

const formRef = ref(null);
defineExpose({
  formRef,
});
</script>
