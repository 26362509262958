export const modules = Object.values(
  import.meta.glob("./*.js", { eager: true, import: "default" }),
).reduce((result, current) => {
  if (typeof current === "function") {
    current().map((route) => result.push(route));
    return result;
  }

  current.map((route) => result.push(route));

  return result;
}, []);

export default modules;
