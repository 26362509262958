const state = () => ({
  icons: [
    {
      id: 1,
      name: "Excellent",
      icon: '<span class="glyphicon glyphicon-education tw-text-[2.6em] tw-text-gray-300 hover:tw-text-gray-400 tw-transition-all tw-duration-75" aria-hidden="true"></span>',
      icon_checked:
        '<span class="dropdown-click glyphicon glyphicon-education tw-text-[2.6em]"aria-hidden="true"></span>',
      icon_disabled:
        '<span class="glyphicon glyphicon-education tw-text-[2.6em] tw-text-gray-300" aria-hidden="true"></span>',
      icon_checked_transition_meeting:
        '<span class="fa-duotone fa-arrow-trend-up fa-green tw-text-[2.6em] tw-pointer-events-none" aria-hidden="true"></span>',
      remarkRequired: false,
      actionRequired: false,
      remarkModelName: "excellent_remark_required",
      actionModelName: "excellent_action_required",
    },
    {
      id: 2,
      name: "Ga zo door",
      icon: '<span class="glyphicon glyphicon-thumbs-up tw-text-[2.6em] tw-text-gray-300 hover:tw-text-gray-400 tw-transition-all tw-duration-75 tw-ml-1" aria-hidden="true"></span>',
      icon_checked:
        '<span class="dropdown-click glyphicon glyphicon-thumbs-up tw-ml-1 tw-text-[2.6em]" style="color: #00BA44;" aria-hidden="true"></span>',
      icon_disabled:
        '<span class="glyphicon glyphicon-thumbs-up tw-text-[2.6em] tw-text-gray-300 tw-ml-1" aria-hidden="true"></span>',
      icon_checked_transition_meeting:
        '<span class="fa-duotone fa-arrow-right fa-light-green tw-text-[2.6em] tw-pointer-events-none" aria-hidden="true"></span>',
      remarkRequired: false,
      actionRequired: false,
      remarkModelName: "good_remark_required",
      actionModelName: "good_action_required",
    },
    {
      id: 3,
      name: "Let op",
      icon: '<span class="glyphicon glyphicon-hand-up tw-text-[2.6em] tw-text-gray-300 hover:tw-text-gray-400 tw-transition-all tw-duration-75" aria-hidden="true"></span>',
      icon_checked:
        '<span class="dropdown-click glyphicon glyphicon-hand-up tw-text-[2.6em]" style="color: #FFA200;" aria-hidden="true"></span>',
      icon_disabled:
        '<span class="glyphicon glyphicon-hand-up tw-text-[2.6em] tw-text-gray-300" aria-hidden="true"></span>',
      icon_checked_transition_meeting:
        '<span class="fa-duotone fa-swap-arrows fa-orange tw-text-[2.6em] tw-pointer-events-none" aria-hidden="true"></span>',
      remarkRequired: false,
      actionRequired: false,
      remarkModelName: "moderate_remark_required",
      actionModelName: "moderate_action_required",
    },
    {
      id: 4,
      name: "Pas op",
      icon: '<span class="glyphicon glyphicon-exclamation-sign tw-text-[2.6em] tw-text-gray-300 hover:tw-text-gray-400 tw-transition-all tw-duration-75" aria-hidden="true"></span>',
      icon_checked:
        '<span class="dropdown-click glyphicon glyphicon-exclamation-sign tw-text-[2.6em]" style="color: #FF0000;" aria-hidden="true"></span>',
      icon_disabled:
        '<span class="glyphicon glyphicon-exclamation-sign tw-text-[2.6em] tw-text-gray-300" aria-hidden="true"></span>',
      icon_checked_transition_meeting:
        '<span class="fa-duotone fa-arrow-rotate-left fa-red tw-text-[2.6em] tw-pointer-events-none" aria-hidden="true"></span>',
      remarkRequired: false,
      actionRequired: false,
      remarkModelName: "weak_remark_required",
      actionModelName: "weak_action_required",
    },
    {
      id: 5,
      name: "", //No rating
      icon: '<span class="glyphicon glyphicon-question-sign tw-text-[2.6em] tw-text-gray-300 hover:tw-text-gray-400 tw-transition-all tw-duration-75" aria-hidden="true"></span>',
      icon_checked:
        '<span class="dropdown-click glyphicon glyphicon-question-sign tw-text-[2.6em]" style="color: gray; opacity: 0.2;" aria-hidden="true"></span>',
      icon_disabled:
        '<span class="glyphicon glyphicon-question-sign tw-text-[2.6em] tw-text-gray-300" aria-hidden="true"></span>',
      icon_checked_transition_meeting:
        '<span class="glyphicon glyphicon-question-sign tw-text-[2.6em] tw-text-gray-300 tw-pointer-events-none" aria-hidden="true"></span>',
    },
  ],
});

export default {
  namespaced: true,
  state,
};
