import { createRouter, createWebHistory } from "vue-router";
import useHelpers from "@/composables/useHelpers";
import { useGlobalStore } from "@/stores/global/global";
import { storeToRefs } from "pinia";
import { useModuleStore } from "store/modules/moduleStore";

// Import routes from modules
import modules from "./modules";
import { useSettingsStore } from "store/modules/settingStore.js";

const {
  mappedTerms,
  schoolId,
  currentShortage,
  checkRole,
  userHasRole,
  generateMenuTitle,
} = useHelpers();

// TODO: Change all the route names to the lowercase
// TODO: Base all route changes on the route name not on the path
// TODO: Define all routes with children in separate modules to keep the files clean
const routes = [
  {
    path: "/",
    name: "Start",
    component: () => import("pages/Startpage.vue"),
    meta: {
      title: `${mappedTerms().environment}`,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("pages/info/Settings.vue"),
    meta: { title: "Instellingen" },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () => import("pages/info/Notifications.vue"),
    meta: { title: "Notificaties" },
  },
  {
    path: "/feedback-formulier",
    name: "feedbackForm",
    component: () => import("pages/info/FeedbackForm.vue"),
    meta: { title: "Feedback formulier" },
  },
  {
    path: "/student-form",
    name: "studentForm",
    component: () => import("pages/info/StudentForm.vue"),
    meta: { title: "Feedback formulier" },
  },
  {
    path: "/help-formulier",
    name: "helpForm",
    component: () => import("pages/info/HelpForm.vue"),
    meta: { title: "Help formulier" },
  },
  {
    path: "/my-meetings",
    name: "myMeetingsIndex",
    redirect: {
      name: "MyMeetings",
    },
    meta: {
      title: "Mijn besprekingen",
      roleRequired: [
        "webmaster",
        "manager",
        "organizer",
        "teamleader",
        "decaan",
        "teacher",
      ],
      icon: "fa-handshake",
      isMenuItem: true,
      menuOrder: 3,
      settingRequired: ["studentMeeting"],
    },
    children: [
      {
        component: () => import("pages/my-meetings/MyMeetingsV2.vue"),
        path: "",
        name: "MyMeetings",
        meta: {
          title: "Mijn besprekingen",
          roleRequired: [
            "webmaster",
            "manager",
            "organizer",
            "teamleader",
            "decaan",
            "teacher",
          ],
          settingRequired: ["studentMeeting"],
        },
        children: [
          {
            path: ":teacherShortage",
            name: "MyMeetingsId",
            meta: {
              roleRequired: ["webmaster", "manager", "organizer", "teamleader"],
            },
            beforeEnter: (to, from, next) => {
              to.meta.title = `Besprekingen van ${to.params.teacherShortage}`;
              next();
            },
          },
        ],
      },
    ],
  },
  {
    path: "/my-ratings",
    name: "MyRatings",
    component: () => import("pages/student/MyRatings.vue"),
    meta: {
      title: "Mijn feedback",
      isMenuItem: true,
      menuOrder: 7,
      roleRequired: ["student"],
      settingRequired: ["studentMeeting"],
      icon: "fa-file-chart-pie",
    },
  },
  {
    path: "/learning-objectives",
    name: "LearningObjectives",
    component: () => import("pages/student/LearningObjectives.vue"),
    meta: {
      title: "Mijn leerdoelen",
      isMenuItem: true,
      menuOrder: 7,
      roleRequired: ["student"],
      settingRequired: ["learningObjectives"],
      icon: "fa-bullseye-arrow",
    },
  },
  {
    path: "/add-school",
    name: "AddSchool",
    component: () => import("pages/admin/AddOrUpdateSchool.vue"),
    meta: {
      title: "School toevoegen",
      roleRequired: ["webmaster"],
    },
  },
  {
    path: "/edit-school/:id",
    name: "EditSchool",
    component: () => import("pages/admin/AddOrUpdateSchool.vue"),
    meta: { title: "School beheren", roleRequired: ["webmaster"] },
  },
  {
    path: "/edit-student/:id",
    name: "EditStudent",
    component: () => import("pages/manage/data/AddOrUpdateStudent.vue"),
    meta: {
      title: `${mappedTerms().students[0].toUpperCase()}${mappedTerms().students.slice(
        1,
      )} beheren`,
      roleRequired: ["webmaster", "manager"],
    },
  },
  {
    path: "/add-employee",
    name: "AddEmployee",
    component: () => import("pages/manage/data/AddOrUpdateEmployee.vue"),
    meta: {
      title: "Medewerker toevoegen",
      roleRequired: ["webmaster", "manager"],
    },
  },
  {
    path: "/edit-employee/:id",
    name: "EditEmployee",
    component: () => import("pages/manage/data/AddOrUpdateEmployee.vue"),
    meta: {
      title: "Medewerker beheren",
      roleRequired: ["webmaster", "manager"],
    },
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: () => import("pages/Unauthorized.vue"),
    meta: {
      title: "403 Oeps, geen toegang...",
      layout: "MainLayout",
    },
  },
  {
    path: "/404",
    name: "error",
    component: () => import("pages/Error.vue"),
    meta: { title: "404 Pagina niet gevonden" },
  },
  {
    // Catch all route
    path: "/:pathMatch(.*)*",
    name: "404",
    redirect: { name: "error" },
  },
];

modules.forEach((module) => {
  routes.push(module);
});

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from) => {
  let token = localStorage.getItem("user_token");
  let ssoEnabled = localStorage.getItem("validate_2fa");
  let schoolChoices = localStorage.getItem("school_choices");

  const { hasSettingEnabled } = useModuleStore();
  const manageRoute = routes.filter((route) => route.name === "manage");

  // Not authenticated -> Go to login
  if (
    token === null &&
    to.name !== "login" &&
    to.name !== "forgotPassword" &&
    to.name !== "resetPassword" &&
    to.name !== "loginByEmail"
  ) {
    return { name: "login" };
  }

  // Authenticated and school choices -> Go to school login choice
  if (
    to.name !== "schoolLoginChoice" &&
    token !== null &&
    schoolChoices !== null
  ) {
    return { name: "schoolLoginChoice" };
  }

  // Authenticated and school choice is set -> Go to start
  if (to.name === "schoolLoginChoice" && schoolChoices === null) {
    return { name: "Start" };
  }

  // Authenticated and 2fa enabled -> Go to 2fa
  if (
    to.name !== "twoFactorAuthentication" &&
    token !== null &&
    ssoEnabled !== null
  ) {
    return { name: "twoFactorAuthentication" };
  }

  // Authenticated -> Go to start
  if ((to.name === "login" || to.name === "loginByEmail") && token !== null) {
    return { name: "Start" };
  }

  // For webmaster, check if currentShortage is set, otherwis redirect to login as employee.
  if (
    (to.name === "MyMeetings" ||
      to.name === "MyLessonGroups" ||
      to.name === "tasks" ||
      to.matched[0].name === "myStudents" ||
      to.matched[0].name === "myLessongroups") &&
    currentShortage === null &&
    checkRole("webmaster")
  ) {
    return {
      name: "LoginAsEmployee",
    };
  }

  // Get modules
  if (schoolId !== null && token !== null && ssoEnabled === null) {
    // TODO: Put this inside the components, this is a heavy load on every route change
    const globalStore = useGlobalStore();
    const { getSchoolModules, getSchool } = globalStore;
    const { hasFetchedSchoolData, hasFetchedModuleData } =
      storeToRefs(globalStore);

    if (!hasFetchedSchoolData.value) {
      await getSchool();
    }

    if (!hasFetchedModuleData.value) {
      await getSchoolModules();
    }

    const settingStore = useSettingsStore();
    const { getSchoolSettings } = settingStore;
    await getSchoolSettings();

    const moduleStore = useModuleStore();
    const { hasSettingEnabled } = moduleStore;

    if (hasSettingEnabled("learningObjectives")) {
      manageRoute[0].meta.roleRequired.push("teacher");
    }

    if (to.name === "manage") {
      if (checkRole("decaan")) {
        if (hasSettingEnabled("portfolio")) {
          return {
            name: "manageSettingsPortfolio",
          };
        }
      } else {
        if (hasSettingEnabled("studentMeeting")) {
          return {
            name: "manageMeetings",
          };
        } else {
          return {
            name: "manageSettingsGeneral",
          };
        }
      }
    }

    if (to.name === "manageSettings") {
      if (checkRole("decaan")) {
        if (hasSettingEnabled("portfolio")) {
          return {
            name: "manageSettingsPortfolio",
          };
        }
      } else {
        return {
          name: "manageSettingsGeneral",
        };
      }
    }

    // TODO: Remove checks when we have the modules in the store
    if (
      (to.name === "Portfolio" ||
        to.name === "tasks" ||
        to.name === "task" ||
        to.name === "MyTasks") &&
      !hasSettingEnabled("portfolio")
    ) {
      return { name: "Unauthorized" };
    }

    if (
      (to.name === "MyRatings" ||
        to.name === "MyStudents" ||
        to.name === "MyMeetings") &&
      !hasSettingEnabled("studentMeeting")
    ) {
      return { name: "Unauthorized" };
    }
  }

  if (!router.hasRoute(to.name)) {
    return { name: "error" };
  }

  if (!isAuthorized(to)) {
    return { name: "Unauthorized" };
  }
});

const isAuthorized = (route) => {
  const moduleStore = useModuleStore();
  const { hasSettingEnabled } = moduleStore;

  const { matched } = route;

  if (!matched[0]?.meta?.roleRequired && !route?.meta?.roleRequired) {
    return true;
  }

  if (
    matched[0]?.meta?.settingRequired &&
    !matched[0].meta.settingRequired.every(hasSettingEnabled)
  ) {
    return false;
  }

  if (
    matched[0]?.meta?.roleRequired &&
    !userHasRole(matched[0].meta.roleRequired)
  ) {
    return false;
  }

  if (route?.meta?.roleRequired && !userHasRole(route.meta.roleRequired)) {
    return false;
  }

  return true;
};

router.afterEach((to, from) => {
  document.title = `${generateMenuTitle(to)} - Catwise`;
});

export default router;
