<template>
  <q-banner v-bind="$attrs" :class="banner.classes" class="tw-items-center">
    <template v-slot:avatar>
      <q-icon :name="banner.icon" size="sm" />
    </template>

    <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </q-banner>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  type: {
    type: String,
    default: "info",
    validator: (value) => {
      return ["info", "warning", "negative", "positive"].includes(value);
    },
  },
});

const typeMappings = computed(() => {
  switch (props.type) {
    case "info":
      return { color: "info", icon: "fas fa-circle-info" };
    case "warning":
      return { color: "warning", icon: "fas fa-exclamation-triangle" };
    case "negative":
    case "error":
      return {
        color: "negative",
        textColor: "white",
        icon: "fas fa-circle-exclamation",
      };
    case "positive":
    case "success":
      return {
        color: "positive",
        textColor: "white",
        icon: "fas fa-circle-check",
      };
    default:
      return {
        color: "info",
        icon: "fas fa-circle-info",
      };
  }
});

const banner = computed(() => {
  const selectedBanner = typeMappings.value;

  selectedBanner.classes = [
    ...(selectedBanner?.color ? [`!tw-bg-${selectedBanner.color}`] : []),
    ...(selectedBanner?.textColor
      ? [`tw-text-${selectedBanner.textColor}`]
      : []),
  ];

  return selectedBanner;
});
</script>

<style lang="scss">
div.q-banner {
  min-height: unset;
  border-radius: $generic-border-radius;

  .q-banner__avatar {
    @apply tw-self-auto;
  }
}
</style>
