<template>
  <component :is="component" />
</template>

<script setup>
import { computed } from "vue";
import ConfettiComponent from "components/global/celebration/ConfettiComponent.vue";
import ClipboardComponent from "components/global/celebration/ClipboardComponent.vue";

const props = defineProps({
  component: {
    type: Object,
    required: true,
    validator: (value) => {
      return ["confetti", "clipboard"].includes(value);
    },
  },
});

const component = computed(() => {
  return props.component === "confetti"
    ? ConfettiComponent
    : ClipboardComponent;
});
</script>
