<template>
  <div
    class="tw-flex tw-min-h-screen tw-items-center tw-justify-center tw-px-4 sm:tw-px-6 lg:tw-px-8"
  >
    <div class="tw-w-full tw-max-w-md">
      <router-link
        :to="{
          name: 'Inloggen',
        }"
      >
        <img
          class="tw-h-25 tw-mx-auto tw-w-auto"
          src="/images/logo.svg"
          alt="Workflow"
        />
      </router-link>

      <slot />
    </div>
  </div>
</template>
<script setup lang="ts"></script>
