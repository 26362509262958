<template>
  <div @click="close" class="tw-cursor-pointer">
    <div
      class="tw-fixed tw-left-1/2 tw-top-1/2 tw-z-[60] tw--translate-x-1/2 tw--translate-y-1/2"
    >
      <Vue3Lottie
        ref="check"
        :animation-data="Done"
        :height="350"
        :width="350"
        :auto-play="false"
        :speed="0.9"
        :loop="false"
        @on-complete="close"
      />
    </div>
    <div
      class="tw-fixed tw-left-0 tw-top-0 tw-z-50 tw-h-full tw-w-full tw-bg-black tw-opacity-50"
    ></div>
  </div>
</template>

<script setup>
import { nextTick, onMounted, ref } from "vue";
import Done from "assets/lottie/leerlingstoplicht_done.json";
import { Vue3Lottie } from "vue3-lottie";

const emit = defineEmits(["close"]);

const check = ref(null);

onMounted(() => {
  nextTick(() => {
    setTimeout(() => {
      play();
    }, 100);
  });
});

const play = () => {
  check.value.play();
};

const close = () => {
  emit("close");
};
</script>
