<template>
  <c-input
    type="time"
    v-bind="$attrs"
    v-model="model"
    :rules
    hide-bottom-space
    :error="errorMessage !== undefined"
    :error-message="errorMessage"
  />
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { isSecondTimeLater } from "common/dateHelpers";

// Define the model
const model = defineModel({
  required: true,
  type: [String, null],
  default: () => "",
});

// Props
const props = defineProps({
  min: {
    type: [String, null],
    default: null,
  },
  required: {
    type: Boolean,
    default: false,
  },
});

// Set refs
const errorMessage = ref(undefined);

const isValidTime = (time) => {
  if (props.min !== null && !isSecondTimeLater(props.min, time)) {
    errorMessage.value = "Deze tijd is niet geldig";

    return false;
  } else {
    errorMessage.value = undefined;

    return true;
  }
};

const rules = computed(() => {
  return [
    ...(props.required ? [(val) => !!val || "Dit veld is verplicht"] : []),
    (val) => isValidTime(val) || "Deze tijd is niet geldig",
  ];
});

watch(
  [() => props.min],
  () => {
    isValidTime(model.value);
  },
  { immediate: true },
);
</script>

<style lang="scss">
.q-input {
  input[type="time"] {
    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }
}
</style>
