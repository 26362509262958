import axios from "axios";
import { defineStore } from "pinia";
import { computed, reactive, ref } from "vue";
import useHelpers from "composables/useHelpers";

export const useGlobalStore = defineStore("global", () => {
  // Helpers
  const { schoolId } = useHelpers();

  // Refs
  const fetchedSchoolModulesId = ref(null);
  const fetchedSchoolDataId = ref(null);

  // Reactive
  const globals = reactive({
    menuOpen: false,
    showConfetti: false,
    schoolSettings: null,
    unreadTasksCount: 0,
    unreadNotificationsCount: 0,
    showSettingsPage: true,
    schoolModules: [],
    school: {},
    statusMessage: {
      message: null,
      active: false,
    },
  });

  // Computed
  const hasFetchedSchoolData = computed(() => {
    return fetchedSchoolDataId.value === schoolId;
  });

  const hasFetchedModuleData = computed(() => {
    return fetchedSchoolModulesId.value === schoolId;
  });

  // Methods
  const getSchoolModules = async () => {
    try {
      const { data } = await axios.get(`/api/school/${schoolId}/modules`);

      globals.schoolModules = data;

      fetchedSchoolModulesId.value = schoolId;
    } catch (error) {
      console.log("🚀 ~ getSchoolModules ~ error:", error);
    }
  };

  const getSchool = async () => {
    try {
      const { data } = await axios.get(`/api/schools/${schoolId}`);

      globals.school = data.data;

      fetchedSchoolDataId.value = schoolId;
    } catch (error) {
      console.log("🚀 ~ getSchool ~ error:", error);
    }
  };

  return {
    globals,
    getSchoolModules,
    hasFetchedSchoolData,
    hasFetchedModuleData,
    getSchool,
  };
});
