<template>
  <q-chip square color="primary" dark v-bind="$attrs">
    <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </q-chip>
</template>

<style lang="scss">
// Had to add body.desktop otherwise styling wouldn't be applied
body.desktop {
  .q-chip {
    &--clickable {
      &:focus {
        @apply tw-shadow-none;
      }
    }

    &.inline {
      @apply tw-flex tw-gap-1;
    }
  }
}
</style>
