import useHelpers from "composables/useHelpers";
const { mappedTerms, checkRole } = useHelpers();

const routes = [
  {
    path: "/webmaster",
    name: "webmasterIndex",
    redirect: {
      name: "LoginAsSchool",
    },
    meta: {
      title: "Webmaster",
      roleRequired: ["webmaster"],
      icon: "fa-crown",
      isMenuItem: true,
      menuOrder: 0,
    },
    children: [
      {
        path: "schools",
        name: "Schools",
        component: () => import("pages/admin/Schools.vue"),
        meta: {
          title: "Scholen beheren",
          roleRequired: ["webmaster"],
          icon: "fa-school",
          isMenuItem: true,
        },
      },
      {
        path: "login-as-school",
        name: "LoginAsSchool",
        component: () => import("pages/admin/LoginAsSchool.vue"),
        meta: {
          title: "Inloggen als school",
          roleRequired: ["webmaster"],
          icon: "fa-right-to-bracket",
          isMenuItem: true,
        },
      },
      {
        path: "login-as-employee",
        name: "LoginAsEmployee",
        component: () => import("pages/admin/LoginAsEmployee.vue"),
        meta: {
          title: "Inloggen als medewerker",
          roleRequired: ["webmaster"],
          icon: "fa-users",
          isMenuItem: true,
        },
      },
      {
        path: "school-stats",
        name: "SchoolStats",
        component: () => import("pages/admin/SchoolStats.vue"),
        meta: {
          title: "School Statistieken",
          roleRequired: ["webmaster"],
          icon: "fa-chart-candlestick",
          isMenuItem: true,
        },
      },
      {
        path: "login-as-student",
        name: "LoginAsStudent",
        component: () => import("pages/admin/LoginAsStudent.vue"),
        meta: {
          title: `Inloggen als ${mappedTerms().student} `,
          roleRequired: ["webmaster"],
          icon: "fa-screen-users",
          isMenuItem: true,
        },
      },
      {
        path: "nps-data",
        name: "NpsData",
        component: () => import("pages/admin/NpsData.vue"),
        meta: {
          title: "NPS",
          roleRequired: ["webmaster"],
          icon: "fa-database",
          isMenuItem: true,
        },
      },
      {
        path: "tips",
        name: "Tips",
        component: () => import("pages/admin/Tips.vue"),
        meta: {
          title: "Tips",
          roleRequired: ["webmaster"],
          icon: "fa-circle-question",
          isMenuItem: true,
        },
      },
      {
        path: "synonyms",
        name: "Vak Synoniemen",
        component: () => import("pages/admin/Synonyms.vue"),
        meta: {
          title: "Vak synoniemen",
          roleRequired: ["webmaster"],
          icon: "fa-bookmark",
          isMenuItem: true,
        },
      },
      {
        path: "import-grades",
        name: "Cijfers Importeren",
        component: () => import("pages/admin/ImportGrades.vue"),
        meta: {
          title: "Cijfers importeren",
          roleRequired: ["webmaster"],
          icon: "fa-square-9",
        },
      },
      {
        path: "email-export",
        name: "E-mail Export",
        component: () => import("pages/admin/EmailExport.vue"),
        meta: {
          title: "E-mail export",
          roleRequired: ["webmaster"],
          icon: "fa-envelope",
          isMenuItem: true,
        },
      },
      {
        path: "sync-logs",
        name: "SyncLogs",
        component: () => import("pages/admin/SyncLogs.vue"),
        meta: {
          title: "Sync logs",
          roleRequired: ["webmaster"],
          icon: "fa-file",
          isMenuItem: true,
        },
      },
      {
        path: "logs",
        name: "Logs",
        component: () => import("pages/admin/LogPage.vue"),
        meta: {
          title: "Logs",
          roleRequired: ["webmaster"],
          isMenuItem: true,
          icon: "fa-file-import",
        },
      },
    ],
  },
];

export default routes;
