<template>
  <c-dialog ref="dialogRef" :return-data="optionsToCommaSeperatedList()">
    <c-field v-model="selectableOptions.model">
      <div class="tw-grid tw-grid-cols-2 tw-gap-2">
        <c-label
          class="tw-col-span-full"
          :label="selectableOptions.labels.title"
        />

        <div class="tw-col-span-full">
          <c-checkbox
            :model-value="selectableOptions.allSelected"
            :label="selectableOptions.labels.selectAll"
            @click="selectableOptions.selectAll"
          />
        </div>

        <div
          class="tw-col-span-1"
          v-for="{ label, value, disabled } in selectableOptions.options"
          :key="label"
        >
          <c-checkbox
            :val="value"
            :label="label"
            :model-value="selectableOptions.model"
            :disable="disabled"
            @update:model-value="selectableOptions.update"
          />
        </div>
      </div>
    </c-field>
  </c-dialog>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { cloneDeep } from "lodash";
import { useSettingsStore } from "store/modules/settingStore.js";
import { storeToRefs } from "pinia";
import CField from "components/wrappers/field/CField.vue";
import { isArray } from "chart.js/helpers";

// Settings store
const settingsStore = useSettingsStore();
const { getSchoolSettings } = settingsStore;
const { schoolSettings } = storeToRefs(settingsStore);

const props = defineProps({
  type: {
    type: [String, null],
    default: () => null,
  },
  model: {
    type: [Object, null],
    default: () => null,
  },
});

// Refs
const _model = ref(null);
const dialogRef = ref(null);

onMounted(async () => {
  await getSchoolSettings();

  // Always enable all options first
  _model.value = options.value.map(({ value }) => value);
});

const options = computed(() => {
  const {
    allow_student_ratings,
    student_check_in,
    enable_se_grade,
    enable_rapport_grade,
  } = schoolSettings.value.student_meeting_settings.settings;

  return [
    {
      label: "Feedback",
      value: "feedback",
      disabled: true,
    },
    ...(allow_student_ratings === 1
      ? [
          {
            label: "Leerling stoplicht",
            value: "leerling-stoplicht",
          },
        ]
      : []),
    {
      label: "Actieplan",
      value: "actieplan",
    },
    ...(student_check_in
      ? [
          {
            label: "Leerling check-in",
            value: "leerling-checkin",
          },
        ]
      : []),
    ...(enable_se_grade
      ? [
          {
            label: "SE cijfer",
            value: "se",
          },
        ]
      : []),
    ...(enable_rapport_grade
      ? [
          {
            label: "Rapport cijfer",
            value: "rapport",
          },
        ]
      : []),
  ];
});

const selectableOptions = computed(() => {
  const allSelected = _model.value?.length === options.value.length;

  return {
    labels: {
      title: "Selecteer de onderdelen die je wilt printen",
      selectAll: allSelected
        ? "Alle opties deselecteren"
        : "Alle opties selecteren",
    },
    model: _model.value || [],
    allSelected,
    options: options.value,
    update: (value) => (_model.value = value),
    selectAll: () => {
      _model.value = allSelected
        ? ["feedback"]
        : options.value.map(({ value }) => value);
    },
  };
});

const optionsToCommaSeperatedList = () => {
  if (!isArray(_model.value)) return;

  let list = "";

  _model.value.forEach((item, index) => {
    list += item;
    if (index < _model.value.length - 1) {
      list += ",";
    }
  });

  return list;
};
</script>
