<template>
  <div
    class="confetti-component tw-fixed tw-left-0 tw-top-0 tw-z-[999] tw-h-screen tw-w-screen tw-overflow-hidden"
    v-if="confettiEnabled"
  >
    <div
      class="absolute h-full w-full cursor-pointer"
      @click="hideCelebration"
    ></div>

    <div
      class="tw-absolute tw-h-full tw-w-full tw-cursor-pointer"
      @click="hideCelebration"
    ></div>
    <div
      class="tw-absolute tw-left-1/2 tw-top-1/2 tw-z-10 tw--translate-x-1/2 tw--translate-y-1/2 tw-text-center"
    >
      <div v-if="!settingDisabled">
        <img
          v-if="!checkRole('student')"
          src="/images/animations/winner-badge.gif"
          width="500"
          class="tw-mx-auto"
          alt="Confetti Badge"
        />

        <div
          class="tw-mx-auto tw-mb-4 tw-w-full tw-max-w-md tw-rounded-md tw-bg-blue-200 tw-p-6"
          v-if="!checkRole('student')"
        >
          <p class="tw-text-xl tw-font-semibold">
            {{ firstName }}, geef jezelf een schouderklopje!
          </p>

          <p class="tw-mt-1">
            Je hebt al jouw {{ mappedTerms().students }} feedback gegeven.
            <span class="tw-capitalize"> {{ mappedTerms().students }}</span
            >, ouders én collega's zullen je dankbaar zijn.
          </p>
        </div>

        <div class="tw-relative">
          <PrimaryButton
            class="tw-text-xl tw-font-bold"
            @click="addConfetti"
            :disabled="amount >= 10"
            ><span v-if="amount < 10"
              >Ik wil <span v-if="amount > 1">&nbsp;NOG MEER&nbsp;</span
              ><span v-else>&nbsp;meer&nbsp;</span> confetti!</span
            ><span v-else>Confetti is op!</span></PrimaryButton
          >

          <p
            class="tw-mt-4 tw-cursor-pointer tw-text-sm tw-underline hover:tw-font-bold"
            @click="disableConfetti"
          >
            Ik wil nooit meer confetti
          </p>
        </div>
      </div>
      <div v-else>
        <div
          class="tw-mx-auto tw-mb-4 tw-w-full tw-max-w-md tw-rounded-md tw-bg-blue-200 tw-p-6"
        >
          Je krijgt geen confetti meer. <br />
          Je kunt dit wijzigen bij instellingen.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref } from "vue";
import PrimaryButton from "components/buttons/PrimaryButton.vue";
import useHelpers from "composables/useHelpers.js";
import { useSettingsStore } from "store/modules/settingStore.js";
import { useCelebrationStore } from "store/modules/celebrationStore.js";
import { storeToRefs } from "pinia";

const timer = ref(10000);
const settingDisabled = ref(false);

const { firstName, mappedTerms, checkRole } = useHelpers();
const amount = ref(0);

// Initialize setting store
const settingStore = useSettingsStore();
const { getUserSettings, updateUserSettings } = settingStore;
const { userSettings } = storeToRefs(settingStore);

const celebrationStore = useCelebrationStore();
const { hideCelebration } = celebrationStore;

const confettiEnabled = computed(() => {
  return userSettings.value.confetti;
});

const addConfetti = () => {
  timer.value = timer.value + 2000;
  amount.value++;
  const div = document.createElement("div");
  div.classList.add("confetti-component__confetti");

  if (amount.value >= 2) {
    div.style.transform = `rotate(${Math.floor(Math.random() * 80)}deg)`;
  }

  const confettiComponent = document.querySelector(".confetti-component");
  confettiComponent.appendChild(div);
};

const disableConfetti = async () => {
  try {
    await updateUserSettings({
      key: "confetti",
      val: false,
    });

    userSettings.value.confetti = false;
    settingDisabled.value = true;
  } catch (error) {
    userSettings.value.confetti = true;
  }
};

onMounted(async () => {
  await getUserSettings;

  nextTick(() => {
    if (confettiEnabled.value) {
      addConfetti();
    }
  });
});
</script>

<style lang="scss">
.confetti-component {
  &__confetti {
    @apply tw-pointer-events-none tw-fixed tw-left-0 tw-top-0 tw-h-screen tw-w-screen;
    background-image: url("~/public/images/animations/confetti.gif");
  }
}
</style>
