<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div
    v-if="timer && timer[0]"
    class="tw-fixed tw-inset-0 tw-z-50 tw-overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="tw-flex tw-min-h-screen tw-items-start tw-justify-center tw-px-4 tw-pb-20 tw-pt-20 tw-pt-4 tw-text-center sm:tw-block sm:tw-p-0"
    >
      <div
        class="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity"
        aria-hidden="true"
        @click="closeModal"
      />

      <span
        class="tw-hidden sm:tw-inline-block sm:tw-h-screen sm:tw-align-middle"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        class="tw-relative tw-inline-block tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-text-left tw-align-bottom tw-shadow-xl tw-transition-all sm:tw-my-8 sm:tw-w-full sm:tw-max-w-md sm:tw-align-middle"
      >
        <div class="tw-bg-white tw-px-4 tw-pb-4 tw-pt-5 sm:tw-p-6 sm:tw-pb-4">
          <div>
            <div class="tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-text-left">
              <p class="tw-mt-2 tw-text-gray-700">
                Omdat je 25 minuten niets hebt gedaan, word je over 5 minuten
                automatisch uitgelogd.
              </p>
              <div class="tw-mt-2">
                <p class="tw-pt-4 tw-text-center tw-font-bold tw-text-red-600">
                  {{ timer[0] }}:{{ timer[1] }}
                </p>
                <div class="tw-py-7">
                  <div
                    class="tw-flex tw-justify-center tw-space-x-4 tw-text-gray-500"
                  >
                    <div>
                      <c-button
                        style="width: 150px"
                        @click="$emit('reset-timer')"
                      >
                        Ingelogd blijven
                      </c-button>
                    </div>
                    <div>
                      <c-button
                        outline
                        style="width: 150px"
                        @click="$emit('logout')"
                      >
                        Uitloggen
                      </c-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["timer"],
  data() {
    return {};
  },
  beforeMount() {},
  methods: {},
};
</script>
