<template>
  <q-toggle
    v-bind="$attrs"
    unchecked-icon="fas fa-times"
    checked-icon="fas fa-check"
    left-label
  >
    <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </q-toggle>
</template>

<style lang="scss">
.q-toggle {
  @apply tw-select-none;

  &.reverse {
    @apply tw-justify-between;
  }
}
</style>
