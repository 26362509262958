<template>
  <q-btn
    unelevated
    color="primary"
    v-bind="$attrs"
    size="xs"
    padding="8px 10px"
    class="tw-aspect-square"
  >
    <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </q-btn>
</template>

<style lang="scss">
.q-btn {
  text-transform: none !important;

  &--outline {
    &:before {
      border: 1px solid $grey;
    }
  }

  &--fab,
  &--fab-mini {
    .q-icon {
      font-size: initial;
    }
  }
}
</style>
