export const pathToComponentName = (filePath) => {
  // Use a regular expression to match the component name without the .vue extension
  const match = filePath.match(/\/([^/]+)\.vue$/);

  if (match === null) {
    throw new Error(
      "Something went wrong converting a path to a component name",
    );
  }

  return match[1];
};
